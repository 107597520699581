import { database } from './index';
import { push, ref, update } from 'https://www.gstatic.com/firebasejs/10.8.0/firebase-database.js';

let focused;

export function capitalize(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function delay(ms, start, src, obj, type) {
  let delay = 0;
  let pause = 0;
  let timeout;
  return new Promise(resolve => {
    let newTimeout = (time) => {
      timeout = setTimeout(() => {
        if (typeof src[obj] === 'number' || src[obj] === 'ended') {
          clearInterval(interval);
          resolve('played');
        }
      }, time);
    }
    newTimeout(ms - (start - src[obj]));
    let interval = setInterval(() => {
      if (!src[obj]) {
        clearInterval(interval);
        clearTimeout(timeout);
        resolve();
      } else if (src[obj] === 'paused' && pause === 0) {
        pause = Date.now() - delay - start;
        clearTimeout(timeout);
      } else if (src[obj] === 'paused') {
        delay += 100;
      } else if (typeof src[obj] === 'number' && pause > 0) {
        newTimeout(ms - pause - ((start - src[obj] > 0) ? start - src[obj] : 0));
        pause = 0;
      }
    }, 100);   
  });
}

export function drag(element, document, window, offsetX, offsetY, session, teacher, event) {
  if (!window || (document.getElementById(session) && !document.getElementById(session).classList.contains('focused-session'))) return;
  let bottom = document.querySelector('.toolbox') ? document.querySelector('.toolbox').offsetHeight + 5.5 : 0;
  let rect = element.getBoundingClientRect();
  let scale = document.documentElement.style.getPropertyValue('--session-scale-x') ? { x: parseFloat(document.documentElement.style.getPropertyValue('--session-scale-x')), y: parseFloat(document.documentElement.style.getPropertyValue('--session-scale-y')) } : { x: 1, y: 1 };
  let vmin = Math.min(window.innerWidth / 500, window.innerHeight / 500);
  let [winHeight, winWidth] = [window.innerHeight - 36 * scale.y, window.innerWidth];
  let clientX, clientY, x, y;
  if (element.classList.contains('right')) {
    if (event) {
      [clientX, clientY] = event.touches ? [event.touches[0].clientX, event.touches[0].clientY] : [event.clientX, event.clientY];
      [x, y] = [winWidth - (clientX + offsetX), clientY - offsetY];
    } else [x, y] = [winWidth - rect.right, rect.top - 36];
    if (x + rect.width > winWidth - 36 && y < 36 * scale.y) [x, y] = [winWidth - rect.width, 0];
    else if (x < 36 && y < 36 * scale.y) [x, y] = [vmin, 0];
    else if (x < 36 && y > winHeight - rect.height - vmin - bottom - 36 * scale.y) [x, y] = [vmin, winHeight - rect.height - vmin - bottom * scale.y];
    else if (x + rect.width > winWidth - 36 && y > winHeight - rect.height - vmin - bottom - 36 * scale.y) [x, y] = [winWidth - rect.width, winHeight - rect.height - vmin - bottom * scale.y];
    else if (x + rect.width > winWidth - 36) x = winWidth - rect.width;
    else if (x < 36) x = vmin;
    else if (y < 36 * scale.y) y = 0;
    else if (y > winHeight - rect.height - vmin - bottom - 36 * scale.y) y = winHeight - rect.height - vmin - bottom * scale.y;
    if (rect.width && rect.height) update(ref(database, `/teachers/${teacher}/online/${session}/${element.id.replace('-div', '')}`), { right: `${x / winWidth * 100}%`, top: `${y / winHeight * 100}%` });
  } else {
    if (event) {
      [clientX, clientY] = event.touches ? [event.touches[0].clientX, event.touches[0].clientY] : [event.clientX, event.clientY];
      [x, y] = [clientX - offsetX, clientY - offsetY];
    } else [x, y] = [rect.left, rect.top - 36];
    if (x + rect.width > winWidth - 36 && y < 36 * scale.y) [x, y] = [winWidth - rect.width, 0];
    else if (x < 36 && y < 36 * scale.y) [x, y] = [0, 0];
    else if (x < 36 && y > winHeight - rect.height - bottom * scale.y) [x, y] = [0, winHeight - rect.height - bottom * scale.y];
    else if (x + rect.width > winWidth - 36 && y > winHeight - rect.height - bottom * scale.y) [x, y] = [winWidth - rect.width, winHeight - rect.height - bottom * scale.y];
    else if (x + rect.width > winWidth - 36) x = winWidth - rect.width;
    else if (x < 36) x = 0;
    else if (y < 36 * scale.y) y = 0;
    else if (y > winHeight - rect.height - bottom * scale.y) y = winHeight - rect.height - bottom * scale.y;
    if (rect.width && rect.height) update(ref(database, `/teachers/${teacher}/online/${session}/${element.id.replace('-div', '')}`), { left: `${x / winWidth * 100}%`, top: `${y / winHeight * 100}%` });
  }
}

export function escape(string) {
  return string.replace(/([!\"#$%&'()*+,.\/:;<=>?@[\\]^`{|}~])/g, '\\$1');
}

export function evaluate(expression) {
  let tokens = [];
  let operatorStack = [];
  let operators = ['+', '-', '×', '*', '÷', '/', '^', '√'];
  let precedence = { '+': 1, '-': 1, '×': 2, '*': 2, '÷': 2, '/': 2, '^': 3, '√': 4 };
  expression = expression.replace(/(\d+)(π|√)/g, "$1×$2");
  expression.match(/(\d+\.\d+|\d+|π|\+|\-|×|\*|÷|\/|\^|√)/g).forEach((token) => {
    if (isNumber(token) || token === 'π') {
      tokens.push(token);
    } else if (operators.includes(token)) {
      while (operators.includes(operatorStack[operatorStack.length - 1]) && precedence[token] <= precedence[operatorStack[operatorStack.length - 1]]) tokens.push(operatorStack.pop());
      operatorStack.push(token);
    }
  });
  while (operatorStack.length > 0) tokens.push(operatorStack.pop());
  let stack = [];
  tokens.forEach((token) => {
      if (isNumber(token)) {
        stack.push(parseFloat(token));
      } else if (token === 'π') {
        stack.push(Math.PI);
      } else if (token === '√') {
        stack.push(Math.sqrt(stack.pop()));
      } else if (token === '+') {
        stack.push(stack.pop() + stack.pop());
      } else if (token === '-') {
        let subtractor = stack.pop();
        stack.push(stack.pop() - subtractor);
      } else if (token === '×' || token === '*') {
        stack.push(stack.pop() * stack.pop());
      } else if (token === '÷' || token === '/') {
        let divisor = stack.pop();
        stack.push(stack.pop() / divisor);
      } else if (token === '^') {
        let exponent = stack.pop();
        let base = stack.pop();
        stack.push(Math.pow(base, exponent));
      }
  });
  return stack[0];
}

export async function getCrashDialog() {
  let dialog = document.createElement('div');
  dialog.classList.add('dialog', 'ui');
  dialog.appendChild(await getHeader('Oops...', dialog, document));
  let dialogMessage = document.createElement('div');
  dialogMessage.classList.add('dialog-message');
  dialogMessage.innerHTML = 'The connection was lost.<br>Check your internet and refresh.';
  dialog.appendChild(dialogMessage);
  let refreshButton = document.createElement('button');
  refreshButton.id = 'refresh-button';
  refreshButton.innerHTML = 'Refresh';
  refreshButton.addEventListener('click', () => location.reload());
  dialog.appendChild(refreshButton);
  try {
  document.querySelector('.app-div').remove();
  document.querySelector('.toolbar').remove();
  } catch {
    location.reload();
  }
  document.body.appendChild(dialog);
}

export function getDate() {
  let date = new Date();
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let day = date.getDate();
  let year = date.getFullYear();
  let suffix;
  if (day === 1 || day === 21 || day === 31) suffix = "st";
  else if (day === 2 || day === 22) suffix = "nd";
  else if (day === 3 || day === 23) suffix = "rd";
  else suffix = "th";
  return { numerical: `${date.getMonth() + 1}/${day}/${year}`, textual: `${months[date.getMonth()]} ${day}${suffix}, ${year}` };
}

export async function getDialog(required, containerDoc, header, message, option1, func1, option2, func2, timer, timerFunc, cancelFunc) {
  return new Promise(async (resolve) => {
    let timeout;
    let dialog = document.createElement('div');
    dialog.classList.add('dialog', 'ui');
    dialog.appendChild(await getHeader(header, dialog));
    let dialogMessage = document.createElement('div');
    dialogMessage.classList.add('dialog-message');
    dialogMessage.innerHTML = message;
    if (header === 'Privacy Policy') {
      dialog.style.height = '320px';
      dialog.style.paddingBottom = '6px';
      dialog.style.width = '320px';
      dialogMessage.classList.add('scroll');
      dialogMessage.style.height = '224px';
    }
    dialog.appendChild(dialogMessage);
    if (option1) {
      let yesButton = document.createElement('button');
      yesButton.classList.add('dialog-yes-button');
      yesButton.innerHTML = option1;
      dialog.appendChild(yesButton);
      yesButton.addEventListener('click', () => {
        dialogOverlay.remove();
        clearTimeout(timeout);
        if (func1) func1();
        resolve(1);
      });
    }
    if (option2) {
      dialog.classList.add('option');
      let noButton = document.createElement('button');
      noButton.classList.add('dialog-no-button');
      noButton.innerHTML = option2;
      dialog.appendChild(noButton);
      noButton.addEventListener('click', () => {
        dialogOverlay.remove();
        clearTimeout(timeout);
        if (func2) func2();
        resolve(2);
      });
    }
    let dialogOverlay = document.createElement('div');
    dialogOverlay.classList.add('overlay', 'dialog');
    dialogOverlay.appendChild(dialog);
    containerDoc.body.appendChild(dialogOverlay);
    if (!required) dialogOverlay.addEventListener('click', (event) => {
      if (event.target.closest('.dialog.ui')) return;
      dialogOverlay.remove();
      clearTimeout(timeout);
      if (cancelFunc) cancelFunc();
      resolve('cancelled');
    });
    if (timer) timeout = setTimeout(() => {
      dialogOverlay.remove();
      if (timerFunc) timerFunc();
      resolve('timeout');
    }, timer);
    if (!option1) resolve();
  });
}

export async function getHeader(title, container, icon, focusable) {
  let header = document.createElement('div');
  header.classList.add('header', title ? null : 'untitled');
  
  let headerLeft = document.createElement('div');
  headerLeft.classList.add('header-left');
  header.appendChild(headerLeft);

  let headerCenter = document.createElement('div');
  headerCenter.classList.add('header-center');
  let headerTitle = document.createElement('div');
  headerTitle.classList.add('header-title');
  if (title && title?.includes('https://')) {
    header.style.height = '100px';
    let headerImage = document.createElement('img');
    headerImage.src = title;
    headerImage.style.border = '3px solid rgb(0, 0, 0, 0.2)';
    headerImage.style.borderRadius = '50%';
    headerImage.style.height = '75px';
    headerImage.style.marginTop = '3px';
    headerImage.style.width = '75px';
    headerTitle.appendChild(headerImage);
  } else headerTitle.innerHTML = title;
  headerCenter.appendChild(headerTitle);
  header.appendChild(headerCenter);

  let headerRight = document.createElement('div');
  headerRight.classList.add('header-right');
  if (focusable) {
    let expandButton = document.createElement('button');
    expandButton.innerHTML = icon.expand;
    let dash = document.getElementById('dash');
    expandButton.addEventListener('click', () => {
      if (focused) {
        focused = false;
        expandButton.innerHTML = icon.expand;
        container.classList.remove('focused');
        if (dash) dash.classList.remove('unfocused');
      } else {
        focused = true;
        expandButton.innerHTML = icon.contract;
        container.classList.add('focused');
        if (dash) dash.classList.add('unfocused');
      }
    });
    headerRight.appendChild(expandButton);
  }
  header.appendChild(headerRight);
  return header;
}

export async function getInputDialog(containerDoc, header, message, placeholder, func) {
  return new Promise(async (resolve) => {
    let dialog = document.createElement('div');
    dialog.classList.add('dialog', 'ui');
    dialog.appendChild(await getHeader(header, dialog));
    let dialogMessage = document.createElement('div');
    dialogMessage.classList.add('dialog-message');
    dialogMessage.innerHTML = message;
    dialog.appendChild(dialogMessage);
    let input = document.createElement('input');
    input.classList.add('dialog-input');
    if (placeholder) input.placeholder = placeholder;
    dialog.appendChild(input);
    let submitButton = document.createElement('button');
    submitButton.innerHTML = 'Submit';
    submitButton.style.marginTop = '6px';
    dialog.appendChild(submitButton);
    let dialogOverlay = document.createElement('div');
    dialogOverlay.classList.add('overlay', 'dialog');
    dialogOverlay.appendChild(dialog);
    containerDoc.body.appendChild(dialogOverlay);
    dialogOverlay.addEventListener('click', (event) => {
      if (event.target.closest('.dialog.ui')) return;
      dialogOverlay.remove();
      resolve('cancelled');
    });
    submitButton.addEventListener('click', () => {
      if (input.value === '') return;
      dialogOverlay.remove();
      if (func) func(input.value);
      resolve(input.value);
    });
  });
}

export function getList(string) {
  if (!string) return '';
  let lines = string.split('<br>');
  let output = '';
  let level = 0;
  let ol;
  lines.forEach((line, index) => {
    if (line.match(/^\d+\./) || line.match(/^\u2022/)) {
      ol = line.match(/^\d+\./);
      if (level === 2) {
        output += ol ? '</ol></li>' : '</ul></li>';
        level--;
      }
      if (level === 0) {
        output += ol ? '<ol>' : '<ul>';
        level = 1;
      }
      output += `<li>${ol ? line.slice(3) : line.slice(2)}`;
      if (index + 1 < lines.length && (lines[index + 1].match(/^[a-z]\./i) || lines[index + 1].match(/^\u25e6/))) {
        output += ol ? '<ol>' : '<ul>';
        level = 2;
      } else {
        output += '</li>';
      }
    } else if (line.match(/^[a-z]\./i) || line.match(/^\u25e6/)) {
      if (level < 2) {
        if (level === 0) {
          output += ol ? '<ol><li><ol>' : '<ul><li><ul>';
          level = 2;
        } else {
          output += ol? '<ol>' : '<ul>';
          level = 2;
        }
      }
      output += `<li>${ol ? line.slice(3) : line.slice(2)}</li>`;
    } else output += `<span>${line}</span>`;
  });
  while (level > 0) {
    output += ol ? '</ol>' : '</ul>';
    if (level > 1) {
      output += '</li>';
    }
    level--;
  }
  return output;
}

export async function getNametag(color, emoji, icon, label, name, tooltip) {
  let nametag = document.createElement('div');
  if (name === '') nametag.classList.add('icon-only');
  nametag.id = 'nametag';
  if (color) nametag.style.background = `linear-gradient(to bottom, ${color.value} -150%, ${color.dark} 150%)`;
  if (label) {
    let nametagLabel = document.createElement('div');
    nametagLabel.classList.add('nametag-label');
    nametagLabel.innerHTML = label;
    nametag.appendChild(nametagLabel);
  }
  if (tooltip) nametag.title = tooltip;
  nametag.innerHTML = `${name}${emoji && icon ? icon?.[emoji?.name] : ''}${label ? ' ' : ''}${label ? label : ''}`;
  return nametag;
}

export function getOrdinal(number) {
  let ordinals = ['next', "first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth", "tenth", "eleventh", "twelfth", "thirteenth", "fourteenth", "fifteenth", "sixteenth", "seventeenth", "eighteenth", "nineteenth", "twentieth"];
  return ordinals[number];
}

export async function getPaymentDialog(containerDoc, header, message, parent, plan, section, student, utc) {
  let result;
  let dialog = document.createElement('div');
  dialog.classList.add('dialog', 'ui');
  dialog.appendChild(await getHeader(header, dialog));
  let dialogMessage = document.createElement('div');
  dialogMessage.classList.add('dialog-message');
  dialogMessage.innerHTML = message;
  dialog.appendChild(dialogMessage);
  let div = document.createElement('div');
  div.id = `paypal-button-container-${plan}`;
  div.style.height = '127px';
  div.style.overflowY = 'clip';
  div.style.width = '100%';
  dialog.appendChild(div);
  let dialogOverlay = document.createElement('div');
  dialogOverlay.classList.add('overlay', 'dialog');
  dialogOverlay.appendChild(dialog);
  containerDoc.body.appendChild(dialogOverlay);
  dialogOverlay.addEventListener('click', (event) => {
    if (event.target.closest('.dialog.ui')) return;
    dialogOverlay.remove();
    result = 'cancelled';
  });
  let script = document.createElement('script');
  script.src = 'https://www.paypal.com/sdk/js?client-id=AUA0E-TX9o2PtTCqejCDEkKKtg77OBTCOJ8yTFc3Kk4cWrEjKFUc1gZX9Rte2oeueo2BNDzmOKApRnGe&vault=true&intent=subscription';
  script.setAttribute('data-sdk-integration-source', 'button-factory');
  script.onload = () => {
    paypal.Buttons({
      style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'subscribe'
      },
      createSubscription: async function(data, actions) {
        return actions.subscription.create({
          custom_id: `${parent}-${section}-${student}`,
          plan_id: plan,
          start_time: utc
        });
      },
      onApprove: async function(data, actions) {
        result = 'approved';
        dialogOverlay.remove();
      }
    }).render(`#paypal-button-container-${plan}`); // Renders the PayPal button
  }
  document.head.appendChild(script);
  while (!result) await sleep(100);
  return result;
}

export async function getPopup(container, icon, keyData, prefix, score) {
  if (!container) return;
  let popup = container.querySelector('#popup');
  if (!popup) {
    popup = document.createElement('div');
    popup.classList.add('popup');
    popup.id = 'popup';
    container.appendChild(popup);
  }
  let message = document.createElement('span');
  if (score === 0.25) {
    if (!keyData?.[`answer${prefix}_25%Message`]) popup.style.display = 'none';
    else {
      popup.innerHTML = null;
      message.innerHTML = keyData[`answer${prefix}_25%Message`];
      popup.appendChild(message);
      popup.style.display = 'flex';
    }
  } else if (score === 0.5) {
    if (!keyData?.[`answer${prefix}_50%Message`]) popup.style.display = 'none';
    else {
      popup.innerHTML = null;
      message.innerHTML = keyData[`answer${prefix}_50%Message`];
      popup.appendChild(message);
      popup.style.display = 'flex';
    }
  } else if ([0.75, '1*'].includes(score)) {
    if (!keyData?.[`answer${prefix}_75%Message`]) popup.style.display = 'none';
    else {
      popup.innerHTML = null;
      popup.innerHTML = keyData[`answer${prefix}_75%Message`] === 'Correct! Simplify to improve.' ? icon.asterisk : '';
      message.innerHTML = keyData[`answer${prefix}_75%Message`];
      popup.appendChild(message);
      popup.style.display = 'flex';
    }
  } else if (score === 1) {
    if (!keyData?.[`answer${prefix}_100%Message`]) popup.style.display = 'none';
    else {
      popup.innerHTML = null;
      popup.innerHTML = icon.check;
      message.innerHTML = keyData[`answer${prefix}_100%Message`];
      popup.appendChild(message);
      popup.style.display = 'flex';
    }
  } else popup.style.display = 'none';
}

export function getPreview(obj, icon) {
  let preview = [];
  let previewOverlay = document.createElement('div');
  previewOverlay.classList.add('overlay');
  let previewContainer = document.createElement('div');
  previewContainer.classList.add('preview-container');
  if (obj.canvas) for (let i = 1; i < obj.canvas.length; i++) {
      if (!preview[i]) {
        preview[i] = document.createElement('div');
        preview[i].classList.add('preview');
        previewContainer.appendChild(preview[i]);
        previewOverlay.appendChild(previewContainer);
        previewOverlay.addEventListener('click', (event) => event.target === previewOverlay ? previewOverlay.remove() : event.stopPropagation());
      }
      preview[i].appendChild(obj.canvas[i]);
      let copyButton = document.createElement('button');
      copyButton.classList.add('copy-button');
      copyButton.innerHTML = 'Copy';
      preview[i].appendChild(copyButton);
      copyButton.addEventListener('click', () => obj.canvas[i].toBlob(blob => { navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })])}, 'image/png'));
      let downloadButton = document.createElement('button');
      downloadButton.classList.add('download-button');
      downloadButton.innerHTML = 'Download';
      preview[i].appendChild(downloadButton);
      downloadButton.addEventListener('click', () => {
        obj.canvas[i].toBlob(blob => {
          let url = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = `${obj.canvas[i].id}.png`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }, 'image/png');
      });
    }
  if (obj.text) for (let i = 1; i < obj.text.length; i++) {
    if (!preview[i]) {
      preview[i] = document.createElement('div');
      preview[i].classList.add('preview');
      previewContainer.appendChild(preview[i]);
      previewOverlay.appendChild(previewContainer);
      previewOverlay.addEventListener('click', (event) => event.target === previewOverlay ? previewOverlay.remove() : event.stopPropagation());
    }
    if (obj.text[i]) preview[i].appendChild(obj.text[i]);
    let copyTextButton = document.createElement('button');
    copyTextButton.classList.add('copy-text-button');
    copyTextButton.innerHTML = 'Copy';
    preview[i].appendChild(copyTextButton);
    copyTextButton.addEventListener('click', () => navigator.clipboard.writeText(obj.text[i].innerHTML.replace(/<br>/gi, '\n')));
  }
  if (preview.length > 1) {
    let previewNavigation = document.createElement('div');
    previewNavigation.classList.add('preview-navigation');
    let previewPrevious = document.createElement('button');
    previewPrevious.classList.add('preview-previous');
    previewPrevious.innerHTML = icon.back;
    previewNavigation.appendChild(previewPrevious);
    let previewIndex = 1;
    let previewCount = document.createElement('div');
    previewCount.classList.add('preview-count', 'ui');
    previewCount.innerHTML = `${previewIndex} / ${preview.length - 1}`;
    previewContainer.appendChild(previewCount);
    let previewNext = document.createElement('button');
    previewNext.classList.add('preview-next');
    previewNext.innerHTML = icon.next;
    previewNavigation.appendChild(previewNext);
    previewContainer.appendChild(previewNavigation);
    preview[previewIndex].style.display = 'block';
    previewPrevious.addEventListener('click', () => {
      previewIndex--;
      if (previewIndex < 1) previewIndex = preview.length - 1;
      previewCount.innerHTML = `${previewIndex} / ${preview.length - 1}`;
      preview.forEach(preview => preview.style.display = 'none');
      preview[previewIndex].style.display = 'block';
    });
    previewNext.addEventListener('click', () => {
      previewIndex++;
      if (previewIndex > preview.length - 1) previewIndex = 1;
      previewCount.innerHTML = `${previewIndex} / ${preview.length - 1}`;
      preview.forEach(preview => preview.style.display = 'none');
      preview[previewIndex].style.display = 'block';
    });
  }
  document.body.appendChild(previewOverlay);
}

function getRandomString(length) {
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < length; i++) randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  return randomString;
}

export function getTimestamp(unixTime) {
  let date = new Date(unixTime);
  let year = date.getFullYear();
  let month = (date.getMonth() + 1);
  let day = date.getDate();
  let hours = date.getHours();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours === 0 ? 12 : hours;
  let minutes = date.getMinutes().toString().padStart(2, '0');
  //let seconds = date.getSeconds().toString().padStart(2, '0');
  let today = new Date();
  if (day === today.getDate() && month === today.getMonth() + 1 && year === today.getFullYear()) return `${hours}:${minutes} ${ampm}`;
  else return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
}

export function getUTC(date) {
  let year = date.getUTCFullYear();
  let month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

function isNumber(string) {
  return !isNaN(parseFloat(string)) && isFinite(string);
}

export async function logMessage(message, data, teacher) {
  let entry = `[] ${message}${data}`;
  push(ref(database, `/teachers/${teacher}/log`), entry);
}

export function select(document, element) {
  if (element.setSelectionRange && (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA')) {
    element.setSelectionRange(element.value.length, element.value.length);
  } else if (element.contentEditable === 'true') {
    let range = document.createRange();
    let selection = document.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function throttle(func, ms) {
  let throttling;
  return function() {
    if (!throttling) {
      func.apply(this, arguments);
      throttling = true;
      setTimeout(() => throttling = false, ms);
    }
  };
}

export function truncate(container, string) {
  let words = string.split(' ');
  container.innerText = string;
  container.title = string;
  while (container.scrollWidth > container.offsetWidth && words.length > 0) {
    words.pop();
    container.innerText = `${words.join(' ')}...`;
  }
}

export function write(obj, path, value) {
  let objData = obj;
  let objOutput = [];
  objOutput[0] = obj;
  let keyOutput = [];
  keyOutput[0] = '';
  let refOutput = [];
  refOutput[0] = ref(database, '/');
  if (path.length === 0) return;
  if (!obj) obj = {};
  for (let i = 1; i <= path.length; i++) {
    if (path[i - 1] === '#') {
      let key = 0;
      while (obj.hasOwnProperty(key)) key++;
      path[i - 1] = key;
    } else if (String(path[i - 1])?.endsWith('*')) {
      let length = parseInt(path[i - 1]);
      let key = getRandomString(length);
      while (obj.hasOwnProperty(key)) key = getRandomString(length);
      path[i - 1] = key;
    }
    if (!obj[path[i - 1]]) obj[path[i - 1]] = {};
    if (i === path.length) {
      obj[path[i - 1]] = value;
    } else {
      obj = obj[path[i - 1]];
      keyOutput[0] += `/${path[i - 1]}`;
      keyOutput[i] = path[i - 1];
      refOutput[i] = ref(database, `${keyOutput[0]}`);
    }
    let objNode = objData;
    for (let j = 1; j <= i; j++) {
      objOutput[i] = objNode[path[j - 1]];
      objNode = objNode[path[j - 1]];
    }
  }
  keyOutput[path.length] = path[path.length - 1];
  refOutput[path.length] = ref(database, `${keyOutput[0]}/${keyOutput[path.length]}`);
  return { key: keyOutput, ref: refOutput, val: objOutput };
}